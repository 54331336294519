<template>
  <div class="console">
    <h5>数据中心</h5>

    <div class="main mt-3 text-center">
      <div class="row">
        <div class="col-sm-3">
          <div class="card">
            <div class="card-body">
              <h3 class="card-title">
                {{ user.balance }}<span style="font-size: 1rem;">元</span>
              </h3>
<!--              <a href="javascript:void(0)" class="btn btn-primary btn-xs float-right">充值</a>-->
              <p class="card-text">账户余额</p>

            </div>
          </div>
        </div>

        <div class="col-sm-3">
          <div class="card">
            <div class="card-body">
              <h3 class="card-title">
                {{ user.balance }}<span style="font-size: 1rem;">元</span>
              </h3>
              <p class="card-text">消耗</p>
            </div>
          </div>
        </div>

        <div class="col-sm-3">
          <div class="card">
            <div class="card-body">
              <h3 class="card-title">
                {{ user.balance }}<span style="font-size: 1rem;">张</span>
              </h3>
<!--              <a href="javascript:void(0)" class="btn btn-primary btn-xs float-right">购票</a>-->
              <p class="card-text">彩票库存</p>
            </div>
          </div>
        </div>

        <div class="col-sm-3">
          <div class="card">
            <div class="card-body">
              <h3 class="card-title">
                {{ user.balance }}<span style="font-size: 1rem;">人</span>
              </h3>
              <p class="card-text">领取人数</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="main mt-5">
      <router-link :to="{path:'console/stat'}" class="float-right">查看统计</router-link>
      <h5>消耗</h5>
      <div class="row mt-3 w-100">
        <line-chart :chart-data="chartData" class="w-100" :width="100" :height="30"/>
      </div>

      <div class="mt-3 row">

      </div>
    </div>

  </div>
</template>
<script>
// import Card from "@/components/Card"
import LineChart from "@/components/LineChart";
import Channel from "@/model/Channel"
import User from "@/model/User";
export default {
  components: {
    // Card
    LineChart
  },
  data() {
    return {
      form: {},
      list: [],
      chartData: {},
      colors: [],
      user: {}
    }
  },
  mounted() {
    this.fillData();
    this.init();
  },
  methods: {
    init() {
      User.find().then(response => {
        if(response.data.code === 0) {
          this.user = response.data.data;
        }
      });

      Channel.getDataList().then(response => {
        if(response.data.code === 0) {
          this.list = response.data.data;
        }
      })
    },
    fillData() {
      Channel.getDataChart().then(response => {
        if(response.data.code === 0) {
          let _chartdata = response.data.data;
          let eDataSets = {
            label: '消耗趋势',
            borderColor: "#3e95cd",
            fill: false,
            data: _chartdata.data
          }
          this.chartData = {
            labels: _chartdata.labels,
            datasets: [eDataSets]
          }
          this.$forceUpdate()
        }
      })

    },
  }
}
</script>